<template>
    <!-- ============================ Hero Banner  Start================================== -->
    <div class="" style="background:#1CA4D4; min-height:100vh">

        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-4 col-md-12 col-sm-12"></div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-2" >

                    <a :href="coreUrl.jobsUrl+'home' ">

                       <base-image style="max-width:150px"
                           imgName="white_logo.png"
                           imgGeo="logo"
                           imgAlt="logo"
                           class="mt-5 mb-2 center-image"
                        >
                        </base-image>

                    </a>

                    <div class="mb-4 text-center text-white">
                        Securely log into your account
                   </div>


                    <!-- Single Wrap -->
                    <create-auth-login>
                    </create-auth-login>
                    <!-- Single Wrap End -->

                    <router-link to="/sign_up"  >
                        <small class="auth-extra-link">
                            <i class="bi bi-power"></i> Create account
                        </small>
                    </router-link>

                    <router-link to="/forgot_password" class="float-right">
                        <small class="auth-extra-link">
                            <i class="bi bi-lock"></i> Forgot Password?
                        </small>
                    </router-link>

                </div>

                <div class="col-lg-4 col-md-12 col-sm-12"></div>

            </div>
        </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

</template>

<script>
import { inject } from 'vue';
import { useStore } from 'vuex';
// @ is an alias to /src
import CreateAuthLogin from '@/components/forms/auth/CreateAuthLogin.vue';

export default {
  name: 'AuthLogin',
  components: {
      CreateAuthLogin
  },
  setup(){
      // get the global vuex store values
      const store = useStore();

      //get the urls
      const coreUrl = inject('coreUrl');

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",false);
      store.dispatch("globals/executeSetFooterMenu",false);


      return {
          coreUrl
      }


  }

};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.center-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>
